<template>
    <div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <strong>Τηλέφωνα:</strong><br>
                    Γεωργία Ντάτση, Εκτελεστική Διευθύντρια: +30 694 836 8915 <br>
                    Βαγγέλης Γιαννοθανάσης, Διευθυντής Επικοινωνίας: +30 698 684 6648 <br>
                    <p>
                        <br><br>
                        Email: <a href="mailto:nutrisense@nutrisense.online">nutrisense@nutrisense.online</a>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 60vw;
    min-height: 60vh;
    font-size: 1.4em;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
</style>
